import {toast} from 'react-toastify';
import {values} from 'lodash';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import toNumber from 'lodash/toNumber';
import {reset} from 'redux-form';

import {checkIsUserLoggedIn, getUserAccessToken} from 'store/reducers/auth/selectors';
import {getCurrentDispatcher} from 'store/reducers/userData/selectors';

import Truck from 'core/entities/Truck/types';

import {fetchChatDrivers, fetchChatTruck} from 'services/restapi';

import * as constants from 'widgets/Chat/constants';
import {chatWSConnect, wsActionCreators} from 'widgets/Chat/redux/actions/wsActions';
import * as types from 'widgets/Chat/redux/actionTypes';
import * as mappers from 'widgets/Chat/redux/mappers';
import * as selectors from 'widgets/Chat/redux/selectors';
import * as chatRequests from 'widgets/Chat/requests';
import {Attachment, Dispatcher, UnreadInfo} from 'widgets/Chat/types';
import {DriverSearchFormValues, MessageFormValues} from 'widgets/Chat/types/form';
import {DriversGroup, MissingTruckNumber} from 'widgets/Chat/types/group';
import {getTruckNumberFromDriver} from 'widgets/Chat/utils';

import notifications from 'components/ui/notifications';

import {mergeGroups} from '../mappers';

export const chatActionCreators = {
    driversGroupsReceived: (payload: {driversGroups: DriversGroup[]}) =>
        ({type: types.DRIVERS_GROUPS_RECEIVED, payload} as const),
    setMissingTruckNumbers: (payload: MissingTruckNumber[]) =>
        ({type: types.MISSING_TRUCK_NUMBERS_RECEIVED, payload} as const),
    moreDriversGroupsReceived: (payload: {driversGroups: DriversGroup[]}) =>
        ({type: types.MORE_DRIVERS_GROUPS_RECEIVED, payload} as const),
    paginationReceived: (payload: {perPage: number; page: number; total: number}) =>
        ({type: types.PAGINATION_RECEIVED, payload} as const),
    toggleChat: () => ({type: types.TOGGLE_CHAT_WIDGET} as const),
    clearSearchedDrivers: () => ({type: types.SEARCHED_DRIVERS_CLEARED} as const),
    leaveDriversGroup: (payload: {cognitoUserID: string}) => ({type: types.LEAVE_DRIVERS_GROUP, payload} as const),
    openDriversGroup: (payload: {cognitoUserID: string}) => ({type: types.OPEN_DRIVERS_GROUP, payload} as const),
    setTruckForDriversGroup: (payload: {truck?: Truck}) =>
        ({type: types.TRUCK_FOR_DRIVERS_GROUP_RECEIVED, payload} as const),
    openDriversGroupWithMessage: (payload: {truckNumber: string; messageText?: string}) =>
        ({type: types.OPEN_DRIVERS_GROUP_WITH_MESSAGE, payload} as const),
    setDriversGroupInitMessages: (payload) => ({type: types.INIT_MESSAGES_RECEIVED, payload} as const),
    insertDriversGroupMoreMessages: (payload) => ({type: types.MORE_MESSAGES_RECEIVED, payload} as const),
    setUnreadDriversGroupsInfo: (payload: UnreadInfo[]) =>
        ({type: types.UNREAD_DRIVERS_GROUPS_INFO_RECEIVED, payload} as const),
    setUnreadDriversGroupMessages: (payload: number[]) =>
        ({type: types.UNREAD_DRIVERS_GROUP_MESSAGES_RECEIVED, payload} as const),
    setChatDispatcherData: (payload: {dispatcher: Dispatcher}) =>
        ({type: types.DISPATCHER_DATA_RECEIVED, payload} as const),
    setAttachmentsUploading: (payload: boolean) => ({type: types.SET_ATTACHMENTS_UPLOADING, payload} as const),
    setAttachmentsToMessage: (payload: {attachments: Attachment[]}) =>
        ({type: types.ATTACHMENTS_TO_MESSAGE_RECEIVED, payload} as const),
    insertDriversGroupAllMessages: (payload: {messages}) =>
        ({type: types.DRIVERS_GROUP_ALL_MESSAGES_RECEIVED, payload} as const),
    searchDriverByTruckNumber: (payload: {formValues: Partial<DriverSearchFormValues>}) =>
        ({type: types.SEARCH_PARAMS_RECEIVED, payload} as const),
    markMessagesAsReadByEveryDispatcher: (payload: {groupID: number}) =>
        ({type: types.MARK_AS_READ_BY_EVERY_DISPATCHER, payload} as const),
    markMessagesAsReadByDispatcher: (payload: {groupID: number}) =>
        ({type: types.MARK_AS_READ_BY_DISPATCHER, payload} as const),
    setOnlyPaginationTotal: (payload: {total: number}) =>
        ({type: types.ONLY_PAGINATION_TOTAL_RECEIVED, payload} as const),
    clearMessageAttachments: () => ({type: types.ATTACHMENTS_TO_MESSAGE_CLEARED} as const),
};

const showAlert = () => {
    const props = {pauseOnFocusLoss: false, closeOnClick: true, autoClose: 2500};

    toast.warn(notifications.chatWarnAlert, {data: {}, ...props});
};

export const receiveDriversGroups = () => async (dispatch, getState) => {
    const state = getState();

    const chatDispatcher = selectors.getChatDispatcher(state);
    const driversGroups = selectors.getDriversGroups(state);
    const isChatOpen = selectors.checkIsChatOpen(state);
    const pagination = selectors.getPagination(state);
    const serverID = selectors.getServerID(state);

    if (!isChatOpen || (driversGroups.allCognitoUserIDs || []).length || !chatDispatcher?.id) {
        return;
    }

    try {
        const {data} = await chatRequests.fetchGroupsByDispatcherID({
            dispatcherID: chatDispatcher.id,
            perPage: pagination.perPage,
            page: pagination.page,
        });

        const {perPage, page, groups, total} = data || {};

        const cognitoDriverIds = groups.map((group) => group.drivers[0]?.cognitoUserId).filter(Boolean);

        const {data: tmsDrivers} = await fetchChatDrivers({cognitoIds: cognitoDriverIds});

        const mappedGroups = mergeGroups({serverID, groups, tmsDrivers});

        dispatch(chatActionCreators.driversGroupsReceived({driversGroups: mappedGroups || []}));
        dispatch(chatActionCreators.paginationReceived({page, perPage, total}));
    } catch (e) {
        console.warn(e);
    }
};

export const getChatDispatcherData = () => async (dispatch, getState) => {
    const state = getState();

    const currentDispatcher = getCurrentDispatcher(state);

    const requestBody = {
        name: currentDispatcher.fake_full_name,
        extension: currentDispatcher.extension,
        tmsUserID: currentDispatcher.user.id,
        tmsEntityID: currentDispatcher.id,
    };

    try {
        const {data} = await chatRequests.fetchChatDispatcherData({requestBody});

        dispatch(chatActionCreators.setChatDispatcherData({dispatcher: data}));
    } catch (e) {
        console.warn(e);
    }
};

export const getDriversGroupsCommonUnreadInfo = () => async (dispatch, getState) => {
    const state = getState();

    const chatDispatcher = selectors.getChatDispatcher(state);

    if (!chatDispatcher) {
        return;
    }

    try {
        const {data} = await chatRequests.fetchAllUnreadMessages({dispatcherID: chatDispatcher.id});

        dispatch(chatActionCreators.setUnreadDriversGroupsInfo(data));
    } catch (e) {
        console.warn(e);
    }
};

export const initializeChat = () => async (dispatch, getState) => {
    const state = getState();

    const isUserLoggedIn = checkIsUserLoggedIn(state);

    if (!isUserLoggedIn) {
        return;
    }

    await dispatch(getChatDispatcherData());

    await dispatch(getDriversGroupsCommonUnreadInfo());

    const webSocket = await dispatch(chatWSConnect());

    if (!webSocket) {
        return;
    }

    dispatch(wsActionCreators.receiveChatConnection({webSocket}));
};

export const openDriversGroup = (params: {cognitoUserID: string}) => (dispatch) => {
    dispatch(chatActionCreators.openDriversGroup(params));
};

export const sendAttachmentsByUpload = (params: {fileList: FileList | null}) => async (dispatch) => {
    const {fileList} = params;

    dispatch(chatActionCreators.setAttachmentsUploading(true));

    const requestBody = mappers.transformFileListToRequestBody(fileList);

    try {
        const {data} = await chatRequests.sendAttachments({requestBody});

        dispatch(chatActionCreators.setAttachmentsToMessage({attachments: data}));
    } catch (e) {
        dispatch(reset(constants.SEND_MESSAGE_FORM));
    } finally {
        dispatch(chatActionCreators.setAttachmentsUploading(false));
    }
};

export const sendMessageByDispatcherToDriversGroup = (params: {message: Partial<MessageFormValues>}) => (
    dispatch,
    getState,
) => {
    const {message} = params;

    const state = getState();

    const isMessageSendingStatus = selectors.checkIsMessageStatusSending(state);
    const chatDispatcher = selectors.getChatDispatcher(state);
    const token = getUserAccessToken(state);

    if (!token || !chatDispatcher?.id) {
        return;
    }

    if (isMessageSendingStatus) {
        return;
    }

    const requestBody = mappers.transformDispatcherMessageToRequestBody(state, message);

    dispatch(wsActionCreators.sendChatMessage(requestBody));
    dispatch({type: types.SET_MESSAGE_STATUS, payload: {messageStatus: 'sending'}});
    dispatch(chatActionCreators.clearMessageAttachments());
};

export const getTruckForDriversGroup = (params: {cognitoUserID: string}) => async (dispatch, getState) => {
    const {cognitoUserID} = params;

    const state = getState();

    const serverID = selectors.getServerID(state);
    const driversGroups = selectors.getDriversGroups(state);
    const driversGroup = driversGroups.byCognitoUserID[cognitoUserID];

    const [driver] = driversGroup.drivers || [];

    const truckNumber = getTruckNumberFromDriver(driver, serverID);

    try {
        const {data} = await fetchChatTruck({truckNumber});

        dispatch(chatActionCreators.setTruckForDriversGroup({truck: data || null}));
    } catch (e) {
        console.warn(e);
    }
};

export const getDriversGroupUnreadMessages = (params: {groupID: number}) => async (dispatch, getState) => {
    const {groupID} = params;

    const state = getState();

    const chatDispatcher = selectors.getChatDispatcher(state);

    if (!chatDispatcher) {
        return;
    }

    try {
        const {data} = await chatRequests.fetchUnreadMessagesByGroup({
            groupID,
            dispatcherID: chatDispatcher.id,
        });

        dispatch(chatActionCreators.setUnreadDriversGroupMessages(data.unreadMessagesByDispatcher));
    } catch (e) {
        console.warn(e);
    }
};

export const getDriversGroupInitLatestMessages = (params: {cognitoUserID: string}) => async (dispatch, getState) => {
    const {cognitoUserID} = params;

    const state = getState();

    const driversGroups = selectors.getDriversGroups(state);
    const driversGroup = driversGroups.byCognitoUserID[cognitoUserID];

    if (!driversGroup?.id) {
        return;
    }

    try {
        const {data} = await chatRequests.fetchLatestMessagesByGroupID({groupID: driversGroup.id});

        dispatch(chatActionCreators.setDriversGroupInitMessages({messages: data.reverse(), cognitoUserID}));

        dispatch(getDriversGroupUnreadMessages({groupID: driversGroup.id}));
    } catch (e) {
        console.warn(e);
    }
};

export const markMessagesAsReadByEveryDispatcher = (params: {groupID: number | null; onFinish?: () => void}) => async (
    dispatch,
) => {
    const {groupID, onFinish} = params;

    if (!groupID) {
        onFinish?.();
        return;
    }

    try {
        await chatRequests.markMessagesAsReadByEveryDispatcher({groupID});

        dispatch(chatActionCreators.markMessagesAsReadByEveryDispatcher({groupID}));
    } catch (e) {
        console.warn(e);
    } finally {
        onFinish?.();
    }
};

export const markMessagesAsReadByDispatcher = (params: {groupID: number | null}) => async (dispatch, getState) => {
    const {groupID} = params;

    if (!groupID) {
        return;
    }

    const state = getState();

    const chatDispatcher = selectors.getChatDispatcher(state);

    if (!chatDispatcher) {
        return;
    }

    try {
        await chatRequests.markMessagesAsReadByDispatcher({groupID, dispatcherID: chatDispatcher.id});

        dispatch(chatActionCreators.markMessagesAsReadByDispatcher({groupID}));
    } catch (e) {
        console.warn(e);
    }
};

export const leaveDriversGroup = (params: {cognitoUserID: string}) => (dispatch, getState) => {
    const {cognitoUserID} = params;

    const state = getState();

    const driversGroups = selectors.getDriversGroups(state);
    const unreadInfo = selectors.getUnreadInfo(state);

    const driversGroup = driversGroups.byCognitoUserID[cognitoUserID];

    dispatch(markMessagesAsReadByDispatcher({groupID: driversGroup.id}));

    const newUnreadInfo = values(omit(unreadInfo || {}, `${driversGroup.id}`));

    dispatch(chatActionCreators.setUnreadDriversGroupsInfo(newUnreadInfo as UnreadInfo[]));
    dispatch(chatActionCreators.leaveDriversGroup({cognitoUserID}));
    dispatch(reset(constants.DRIVER_SEARCH_FORM));
};

export const getDriversGroupMoreMessages = (params: {cognitoUserID: string}) => async (dispatch, getState) => {
    const {cognitoUserID} = params;

    const state = getState();

    const driversGroups = selectors.getDriversGroups(state);
    const driversGroup = driversGroups.byCognitoUserID[cognitoUserID];

    const oldestMessage: any = head(driversGroup.messages);

    try {
        const {data} = await chatRequests.fetchLatestMessagesByGroupAndMessageIDs({
            messageID: oldestMessage.id,
            groupID: driversGroup.id,
        });

        dispatch(chatActionCreators.insertDriversGroupMoreMessages({messages: data.reverse(), cognitoUserID}));
    } catch (e) {
        console.warn(e);
    }
};

export const openChatGroupWithMessageToFill = (params: {truckNumber: string; messageText?: string}) => async (
    dispatch,
) => {
    // TODO: temporarily closed, need a new one action for invoices
    // const {truckNumber, messageText} = params;
    //
    // await dispatch(getDriversGroups());
    //
    // dispatch(chatActionCreators.openDriversGroupWithMessage({truckNumber, messageText}));
};

export const searchDriverByTruckNumber = (params: {
    formValues: Partial<DriverSearchFormValues>;
    endSearching: () => void;
}) => async (dispatch, getState) => {
    const {formValues, endSearching} = params;

    const state = getState();

    const searchFormValues = pick(params, 'formValues');

    if (!formValues?.truckNumber) {
        dispatch(chatActionCreators.searchDriverByTruckNumber(searchFormValues));

        endSearching();

        return;
    }

    const {byCognitoUserID} = selectors.getDriversGroups(state);
    const serverID = selectors.getServerID(state);

    const oldGroups = Object.values(byCognitoUserID || {});

    const trucks = oldGroups
        .map((group) => group.drivers)
        .flat()
        .map((driver) => driver?.trucks || [])
        .flat();

    const isExistLoadedTruck = (trucks || []).find(
        (truck) => truck?.number === formValues?.truckNumber && truck.serverID === serverID,
    );

    if (isExistLoadedTruck) {
        dispatch(chatActionCreators.searchDriverByTruckNumber(searchFormValues));

        endSearching();

        return;
    }

    try {
        const {data} = await chatRequests.searchGroupByTruckNumberRequest({truckNumber: formValues?.truckNumber});

        const isGroupExist = (oldGroups || []).find((oldGroup) => oldGroup?.id === data?.group?.id);

        if (!isGroupExist) {
            dispatch(chatActionCreators.moreDriversGroupsReceived({driversGroups: data?.group ? [data.group] : []}));
            dispatch(chatActionCreators.searchDriverByTruckNumber(searchFormValues));
        }
    } catch (e) {
        showAlert();
    } finally {
        endSearching();
    }
};

export const clearSearchedDrivers = () => (dispatch) => {
    dispatch(chatActionCreators.clearSearchedDrivers());
};

export const getDriversGroupAllMessages = (params: {cognitoUserID: string; onFinish?: () => void}) => async (
    dispatch,
    getState,
) => {
    const {cognitoUserID, onFinish} = params;

    const state = getState();

    const driversGroups = selectors.getDriversGroups(state);
    const driversGroup = driversGroups.byCognitoUserID[cognitoUserID];

    if (!driversGroup.id) {
        onFinish?.();
        return;
    }

    try {
        const {data} = await chatRequests.fetchAllGroupMessages({groupID: driversGroup.id});

        dispatch(chatActionCreators.insertDriversGroupAllMessages({messages: data}));
    } catch (e) {
        console.warn(e);
    } finally {
        onFinish?.();
    }
};

export const openDriversGroupFromTripMonitorByTruckNumber = (params: {
    endSearching: () => void;
    truckNumber: string;
}) => async (dispatch, getState) => {
    const {truckNumber, endSearching} = params;

    if (!truckNumber) {
        endSearching();

        return;
    }

    const loadedDriver = mappers.getDriverFromDriversGroupsByTruckNumber({state: getState().chat, truckNumber});

    if (!isEmpty(loadedDriver)) {
        dispatch(openDriversGroup({cognitoUserID: loadedDriver.cognitoUserId}));

        endSearching();

        return;
    }

    try {
        const {data} = await chatRequests.searchGroupByTruckNumberRequest({truckNumber});

        const {group} = data || {};

        if (isEmpty(group)) {
            showAlert();

            return;
        }

        dispatch(chatActionCreators.moreDriversGroupsReceived({driversGroups: [group]}));

        const newDriver = mappers.getDriverFromDriversGroupsByTruckNumber({state: getState().chat, truckNumber});

        dispatch(openDriversGroup({cognitoUserID: newDriver.cognitoUserId}));

        // total = 9999 prevent button load more when only one chanel exist
        dispatch(chatActionCreators.setOnlyPaginationTotal({total: 9999}));
    } catch (e) {
        showAlert();
    } finally {
        endSearching();
    }
};

export const receiveMissingTruckNumbers = (params: {groups: string[]}) => async (dispatch) => {
    const {groups} = params;

    if (isEmpty(groups)) {
        return;
    }

    const groupIDs = groups.map((item) => (isString(item) ? toNumber(item) || null : null)).filter(isNumber);

    if (isEmpty(groupIDs)) {
        return;
    }

    try {
        const {data} = await chatRequests.fetchMissingTruckNumbers({requestBody: {groupIDs}});

        dispatch(chatActionCreators.setMissingTruckNumbers(data));
    } catch (e) {
        console.warn(e);
    }
};

export const loadMoreGroups = (params: {hideLoader}) => async (dispatch, getState) => {
    const {hideLoader} = params;

    const state = getState();

    const chatDispatcher = selectors.getChatDispatcher(state);
    const pagination = selectors.getPagination(state);
    const serverID = selectors.getServerID(state);

    try {
        const {data} = await chatRequests.fetchGroupsByDispatcherID({
            dispatcherID: chatDispatcher?.id,
            perPage: pagination.perPage,
            page: pagination.page + 1,
        });

        const {perPage, page, groups, total} = data || {};

        const cognitoDriverIds = groups.map((group) => group.drivers[0]?.cognitoUserId).filter(Boolean);

        const {data: tmsDrivers} = await fetchChatDrivers({cognitoIds: cognitoDriverIds});

        const mappedGroups = mergeGroups({serverID, groups, tmsDrivers});

        dispatch(chatActionCreators.moreDriversGroupsReceived({driversGroups: mappedGroups || []}));
        dispatch(chatActionCreators.paginationReceived({page, perPage, total}));
    } catch (e) {
        console.warn(e);
    } finally {
        if (hideLoader) {
            hideLoader();
        }
    }
};
